import grapePerksAppService from '@/api';
import configApi from '@/api/config';

/**
 * AppSettingsService service api
 * @class AppSettingsService
 */
class AppSettingsService {
  constructor() {
    this.baseUrl = configApi.baseRoutesUrls.appSettings;
  }

  /**
   * Get all app settings from
   * @function getAll
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  get = () => grapePerksAppService.get(this.baseUrl)
    .then((resp) => resp.data);

  /**
   * Update app settings
   * @function edit
   * @param {Object} payload - payload to update
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  update = (payload) => grapePerksAppService.put(
    this.baseUrl, payload,
  ).then((resp) => resp.data);
}

const singletonInstance = new AppSettingsService();

/**
 * app settings service api instance
 * @exports api/modules/app-settings
 * */
export default singletonInstance;
