<template>
  <div class="vx-row justify-center">
    <div class="vx-col w-full md:w-1/2">
      <vx-card
        no-shadow
        card-border>
        <slot name="title">
          <div class="flex items-center justify-end md:justify-between  mb-5">
            <h2 class="hidden md:block">
              {{ $t('$UserSettings.$Shop.$ManageTopUps.$AutoTopUpAccount.TabTitle') }}
            </h2>
          </div>
        </slot>

        <form key="unique">
          <div class="vx-row">
            <div class="vx-col w-full mb-4">
              <label class="vs-input--label">
                {{ $t('BalanceWarning') }}
                <vx-tooltip
                  :text="$t('BalanceWaningHelpMsg')"
                  position="top"
                  class="inline-block">
                  <feather-icon
                    icon='HelpCircleIcon'
                    svgClasses="h-4 w-4 hover:text-primary cursor-pointer"/>
                </vx-tooltip>
              </label>

              <vs-input
                v-model.number="model.alertBalanceWarning"
                class="w-full"
                type="number"
                min="0"
                :name="$t('BalanceWarning')"
                v-validate="'min_value:0'"
                data-vv-validate-on="blur|input"
                icon-no-border
                icon="icon icon-dollar-sign"
                icon-pack="feather"
                :danger="errors.has($t('BalanceWarning'))"
                :danger-text="errors.first($t('BalanceWarning'))"
                val-icon-danger="clear"/>
            </div>
          </div>

          <transition name="zoom-fade">
            <div v-show="model.activateAutoTopUpAccount">
              <div class="vx-row">
                <div class="vx-col w-full mb-4">
                  <label class="vs-input--label required">
                    {{ $t('RechargeThreshold') }}
                    <vx-tooltip
                      :text="$t('RechargeThresholdHelpMsg')"
                      position="top"
                      class="inline-block">
                      <feather-icon
                        icon='HelpCircleIcon'
                        svgClasses="h-4 w-4 hover:text-primary cursor-pointer"/>
                    </vx-tooltip>
                  </label>

                  <vs-input
                    v-model.number="model.rechargeThreshold"
                    class="w-full"
                    type="number"
                    min="0"
                    :name="$t('RechargeThreshold')"
                    v-validate="`required:${model.activateAutoTopUpAccount}|min_value:0`"
                    data-vv-validate-on="blur|input"
                    icon-no-border
                    icon="icon icon-dollar-sign"
                    icon-pack="feather"
                    :danger="errors.has($t('RechargeThreshold'))"
                    :danger-text="errors.first($t('RechargeThreshold'))"
                    val-icon-danger="clear"/>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col w-full mb-4">
                  <label class="vs-input--label required">
                    {{ $t('AutoRechargePackage') }}
                    <vx-tooltip
                      :text="$t('AutoRechargePackageHelpMsg')"
                      position="top"
                      class="inline-block">
                      <feather-icon
                        icon='HelpCircleIcon'
                        svgClasses="h-4 w-4 hover:text-primary cursor-pointer"/>
                    </vx-tooltip>
                  </label>

                  <v-select
                    v-model="model.prepaidPackage"
                    class="w-full"
                    :class="{'border-danger': errors.has($t('AutoRechargePackage'))}"
                    label="price"
                    appendToBody
                    :multiple="false"
                    :closeOnSelect="true"
                    :options="appSettingsPackagesWithNumber"
                    :clearable="false"
                    @input="validatePrepaidPackage()">
                    <template #option="{price}">
                      <strong>
                        {{ $t('PrepaidPackageOptionText', {
                        price: $options.filters.dollar(price),
                      }) }}
                      </strong>
                    </template>
                    <template #selected-option="{price}">
                      <strong>
                        {{ $t('PrepaidPackageOptionText', {
                        price: $options.filters.dollar(price),
                      }) }}
                      </strong>
                    </template>
                  </v-select>

                  <vs-input
                    :value="model.prepaidPackage ? 'ok' : ''"
                    class="w-full"
                    type="hidden"
                    :name="$t('AutoRechargePackage')"
                    v-validate="`required:${model.activateAutoTopUpAccount}`"
                    :danger="errors.has($t('AutoRechargePackage'))"
                    :danger-text="errors.first($t('AutoRechargePackage'))"/>
                </div>
              </div>
            </div>
          </transition>

          <div class="vx-row">
            <div class="vx-col w-full mb-4 flex items-center">
              <vs-checkbox
                v-model="model.activateAutoTopUpAccount"
                class="inline-flex mt-0"
                @click="activateAutoTopUpAccountWasChanged=true">
              </vs-checkbox>
              <span>
                {{ $t('AutoTopUpCheckboxText') }}

                <vx-tooltip
                  :text="$t('AutoTopUpCheckboxHelpMsg')"
                  position="top"
                  class="inline-block">
                  <feather-icon
                    icon='HelpCircleIcon'
                    svgClasses="h-4 w-4 hover:text-primary cursor-pointer"/>
                </vx-tooltip>
              </span>
            </div>
          </div>

          <div class="flex items-center justify-between">
            <vs-button
              id="user-account-purchases-auto-top-up-save-button"
              class="vs-con-loading__container"
              color="primary"
              @click="saveAutoTopUpConfig()">
              {{ $t('$General.Update') }}
            </vs-button>

            <vs-button
              v-show="formIsChanged"
              class="vs-button-cancel-flat"
              color="danger"
              @click="setModel()">
              {{ $t('$General.Cancel') }}
            </vs-button>
          </div>

          <vs-divider></vs-divider>

          <div>
            <small class="text-justify">
              <em>
                {{ $t('AutoTopUpFooterMsg') }}
              </em>
            </small>
          </div>
        </form>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import tenantService from '@/api/modules/tenant.service';
import appSettingsService from '@/api/modules/app-settings.service';

/**
 * Component for the user update his auto top up
 *
 * @module views/modules/user-account/UserAccountPurchasesAutoTopUpAccount
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object | null} [appSettings=null] - app settings
 * @vue-data {Object | null} [payload=null] - auto top up payload
 * @vue-data {boolean} [activateAutoTopUpAccountWasChanged=false] - indicate if switch was changed
 * @vue-data {Object} [model={...}] - auto top up model
 * @vue-computed {boolean} formIsChanged - indicate if form is changed
 * @vue-computed {Object[]} appSettingsPackagesWithNumber - app settings packages with numbers
 * @vue-computed {Object[]} appSettingsPackages - app settings packages
 * @vue-event {void} getInitialData - get initial data
 * @vue-event {void} getAppSettings - get app settings
 * @vue-event {void} getAutoTopUpConfig - get auto top up config
 * @vue-event {void} saveAutoTopUpConfig - save auto top up config
 * @vue-event {void} setModel - set auto top up model from payload
 * @vue-event {void} validatePrepaidPackage - validate the prepaid package field
 */
export default {
  name: 'UserAccountPurchasesAutoTopUpAccount',
  i18n: {
    messages: {
      en: {
        RechargeThreshold: 'Recharge threshold',
        RechargeThresholdHelpMsg: 'It is the amount to which your balance must be lowered in order for the auto top-up to be activated.',
        BalanceWarning: 'Balance warning',
        BalanceWaningHelpMsg: 'It is the amount to which your balance must be lowered in order to send you an email alert.',
        AutoRechargePackage: 'Auto-recharge package',
        AutoRechargePackageHelpMsg: 'It is the prepaid package that will be charged to your account once the threshold is reached.',
        AutoTopUpFooterMsg: 'The payment of the prepaid package that you select in your auto top-up configuration will be made with the default payment method that you have in your payment methods.',
        PrepaidPackageOptionText: 'Prepaid package of {price}',
        UpdatedSuccessNotifyTitle: 'Updated auto top-up',
        UpdatedSuccessNotifyMsg: 'Auto top-up updated successfully',
        AutoTopUpCheckboxText: 'Activate automatic top-up',
        AutoTopUpCheckboxHelpMsg: 'By ticking this checkbox you will activate the option to automatically top up a prepaid package once you have passed the threshold you set.',
      },
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      appSettings: null,
      payload: null,
      activateAutoTopUpAccountWasChanged: false,
      model: {
        activateAutoTopUpAccount: false,
        rechargeThreshold: null,
        prepaidPackage: null,
        alertBalanceWarning: null,
      },
    };
  },
  computed: {
    formIsChanged() {
      return Object.keys(this.validationFields).some((key) => this.validationFields[key].changed)
        || this.activateAutoTopUpAccountWasChanged;
    },
    appSettingsPackagesWithNumber() {
      return this.appSettingsPackages.map((pack, index) => ({
        ...pack,
        packageNumber: index,
      }));
    },
    appSettingsPackages() {
      return this.appSettings && this.appSettings.packages
      && Array.isArray(this.appSettings.packages)
        ? this.appSettings.packages
        : [];
    },
  },
  watch: {
    'model.activateAutoTopUpAccount': function (val) {
      if (!val) {
        this.model.rechargeThreshold = null;
        this.model.prepaidPackage = null;
      }

      this.$validator.reset();
    },
  },
  created() {
    if (this.$mq === this.$enums.mqBreakpoints.DESKTOP) {
      window.history.pushState({}, null, '/purchases/manage-top-ups/auto-top-up-account');
    }

    this.getInitialData();
  },
  methods: {
    async getInitialData() {
      await this.getAppSettings();
      await this.getAutoTopUpConfig();
    },
    async getAppSettings() {
      const resp = await appSettingsService.get();
      this.appSettings = resp.data ? resp.data : {};
    },
    async getAutoTopUpConfig() {
      const resp = await tenantService.getConfigAutoTopUpAccount();

      if (resp && resp.data) {
        this.payload = resp.data;
        this.setModel();
      }
    },
    async saveAutoTopUpConfig() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid && this.model.activateAutoTopUpAccount) {
        document.getElementsByName([this.$validator.errors.items[0].field])[0].focus();
        return;
      }

      this.$vs.loading({
        background: 'primary',
        color: 'white',
        container: '#user-account-purchases-auto-top-up-save-button',
        scale: 0.45,
      });

      try {
        const resp = await tenantService.updateAutoTopUpAccountConfig({
          activateAutoTopUpAccount: this.model.activateAutoTopUpAccount,
          rechargeThreshold: this.model.rechargeThreshold || null,
          prepaidPackageNumber: this.model.prepaidPackage || this.model.prepaidPackage === 0
            ? this.model.prepaidPackage.packageNumber
            : null,
          alertBalanceWarning: this.model.alertBalanceWarning || null,
        });

        if (resp && resp.data) {
          this.payload = resp.data;
          this.setModel();
        }

        this.$showSuccessActionNotification({
          title: this.$t('UpdatedSuccessNotifyTitle'),
          text: this.$t('UpdatedSuccessNotifyMsg'),
        });
      } finally {
        this.$vs.loading.close('#user-account-purchases-auto-top-up-save-button > .con-vs-loading');
      }
    },
    setModel() {
      const prepaidPackageNumber = this.payload.prepaidPackageNumber
      || this.payload.prepaidPackageNumber === 0
        ? this.payload.prepaidPackageNumber
        : null;
      this.model.activateAutoTopUpAccount = this.payload.activateAutoTopUpAccount || false;
      this.model.rechargeThreshold = this.payload.rechargeThreshold || null;
      this.model.prepaidPackage = this.appSettingsPackagesWithNumber[prepaidPackageNumber] || null;
      this.model.alertBalanceWarning = this.payload.alertBalanceWarning || null;

      this.$validator.reset();
      this.activateAutoTopUpAccountWasChanged = false;
    },
    validatePrepaidPackage() {
      setTimeout(() => {
        this.$validator.validate(this.$t('AutoRechargePackage'));
      });
    },
  },
};
</script>
